@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body, html {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-family: "Lucida Grande", "SF Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;

	* {
		box-sizing: border-box;
	}
}

.clearfix {
	&::after {
		content: "";
		clear: both;
		display: table;
	}
}

#launcher {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 0;
	background: #4aceff;
	display: flex;
	justify-content: center;
	align-items: center;
	transform-origin: center left;
}

#hard_drive {
	color: #FFF;
	display: block;
	line-height: 1.4em;
	margin: 0 auto;
	text-align: center;
	text-shadow: 0 0 2px rgba(0,0,0,.8), 0 0 3px rgba(0,0,0,.8), 0 0 2px rgba(0,0,0,.8);
	width: 80px;
	z-index: 1;
}

#finder {
	width: 80%;
	height: 80%;
	z-index: 1;
	

	&__header {
		background: rgb(167,167,167);
		background: -moz-linear-gradient(0deg, rgba(167,167,167,1) 0%, rgba(208,208,208,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(167,167,167,1) 0%, rgba(208,208,208,1) 100%);
		background: linear-gradient(0deg, rgba(167,167,167,1) 0%, rgba(208,208,208,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7a7a7",endColorstr="#d0d0d0",GradientType=1);
		border: 1px solid #515151;
		border-radius: 8px 8px 0 0;
		font-size: 13px;
		height: 40px;
		padding: 3px 8px 7px 8px;
		position: relative;
		text-align: center;
		text-shadow: 0 1px 0 #ebebeb;
		z-index: 2;
		box-shadow: 1px 11px 20px #000;
		
		&-controls {
			width: 25%;
			max-width: 240px;
			min-width: 60px;
			display: flex;

			
				

				.glossy-button {
					text-indent: -6000px;
					display: inline-block;
					position: relative;
					background-color: #AAA;
					width: 16px;
					height: 16px;
					border: none;
					border-radius: 50px;
					margin: 0 0 0 12px;
					box-shadow:
						inset 0 5px 12px hsla(0, 0%, 100%, 0.1), /* top light */
						inset 0 2px 3px rgba(90,31,25,0.5),
						1px 2px 1px rgba(255,255,255,.2); /* chisel */
					cursor: pointer;
					transition: transform 0.1s, box-shadow 0.1s;

					&:before {
						content: '';
						display: block;
						position: absolute;
						left: 8px;
						right: 8px;
						top: 2px;
						height: 8px;
						border-radius: 8pxpx;
						background: linear-gradient(
							hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0) );
					}

					
					&--gold { 
						background-color: #EA0; 
						background-image: linear-gradient( /* chrome */
							hsla(0, 0%, 100%, 1), #EA0 46%
						);
					}
					&--green { 
						background-color: #74b155; 
						background-image: linear-gradient( /* chrome */
							hsla(0, 0%, 100%, 1), #74b155 46%
						);
					}
					&--red { 
						background-color: #E21; 
						background-image: linear-gradient( /* chrome */
						hsla(0, 0%, 100%, 1), #E21 46%

					);
					}
				}
				
				
				
				
			
		}

		

		&-search {
			width: 25%;
			max-width: 240px;
			min-width: 142px;
			text-align: right;

			input {
				&[type="search"] {
					background: #FFF url(/images/icons/magnifying-glass.gif) no-repeat 4px 4px;
    			border: 1px inset #CCC;
    			color: #444;
    			font: 13px/normal "Lucida Grande","Lucida Sans Unicode",Arial,Verdana,sans-serif;
    			height: 22px;
    			padding: 1px 1px 1px 20px;
					border-radius: 16px
				}
			}
		}
	}

	&__content {
		background: #fff;
		border: 1px solid #515151;
		border-top: none;
		border-bottom: none;
		min-height: 250px;
		height: 80%;
		overflow: hidden;
		box-shadow: 1px 11px 20px #000;
		position: relative;
		z-index: 3;

		.column {
			border-right: 1px solid #e0e0e0;
			overflow-x: hidden;
			overflow-y: scroll;
			padding-top: 1px;
			width: 214px;

			&__asset {
				padding: 10px 20px 30px;
				width: 320px;
				font-size: 11px;

				iframe {
					width: 100%;
					height: auto;
					
					&[src*="soundcloud"] {
						aspect-ratio: 1 / 1;
					}

					&[src*="youtube"] {
						aspect-ratio: 16 / 9;
					}
				}

				p {
					margin: 0 0 15px;
				}

				dl {
					margin-top: 15px;
					margin-bottom: 5px;

					dt {
						clear: both;
						color: #5d5d5d;
						float: left;
						font-weight: bold;
						line-height: 1.3;
						margin: 0 0 3px 0;
						text-align: right;
						width: 32%;
					}

					dd {
						float: right;
    				line-height: 1.3;
    				margin: 0 0 3px 15px;
				    width: calc(68% - 15px);

					}
				}
			}
		}

		.finder {
			&__item {
				background-position: 6px center;
				height: 18px;
				background-size: 16px auto;

				& + .finder__item {
					margin-top: 1px;
				}

				&.past-active {
					background-color: #d4d4d4;
				}
				&.active {
					background-color: #3874d7;
					color: #fff;
				}

				a {
					display: block;
					padding-left: 20px;
					padding-right: 16px;
					overflow: hidden;
					padding: 4px 14px 4px 29px;
					text-overflow: ellipsis;
					white-space: nowrap;
					line-height: 11px;
				}

				&-generic-folder {
					background-color: #fff;
					background-image: url(./images/icons/folder-arrow.png);
					background-position: right center;
					background-repeat: no-repeat;
    			background-size: 11px 54px;
    			height: 18px;

					&.active {
						background-color: #3874d7;
						background-position: right top;
						color: #fff;
					}
					&.past-active {
						background-color: #d4d4d4;
						background-position: right bottom;
					}

					a { 
						background: url(./images/icons/GenericFolder.png) no-repeat 0 0;
						background-size: auto 13px;
    				background-position: 6px 2px;
						
					}
					
					&::after {
						content: "";
						display: block;
						clear: both;
					}
				}

				&-file {
					&-bleep {
						a { 
							background: url(./images/icons/bleep.png) no-repeat 0 0;
							background-size: auto 14px;
							background-position: 6px 2px;
						}
					}

					&-facebook {
						a { 
							background: url(./images/icons/facebook.png) no-repeat 0 0;
							background-size: auto 14px;
							background-position: 6px 2px;
						}
					}

					&-image {
						a { 
							background: url(./images/icons/image.png) no-repeat 0 0;
							background-size: auto 14px;
							background-position: 6px 2px;
						}
					}

					&-itunes {
						a {
							background: url(./images/icons/iTunes-32x32.png) no-repeat 0 0;
							background-size: auto 15px;
							background-position: 7px 2px;
						}
					}

					&-link,
					&-offsite-link {
						a { 
							background: url(./images/nav/link.png) no-repeat 0 0;
							background-size: auto 14px;
							background-position: 9px 3px;
						}
					}

					&-soundcloud {
						a { 
							background: url(./images/icons/soundcloud-logo-icon.svg) no-repeat 0 0;
							background-size: 20px auto;
							background-position: 4px center;
						}
					}

					&-text {
						a { 
							background: url(./images/nav/document.png) no-repeat 0 0;
							background-size: auto 15px;
							background-position: 7px 2px;
						}
					}

					&-youtube {
						a {
							background: url(./images/icons/YouTube.svg) no-repeat 0 0;
							background-size: auto 12px;
							background-position: 6px center;
						}
					}

				}
			}
		}
	}

	&__footer {
		background-color: #ebebeb;
    background: -moz-linear-gradient(100% 100% 90deg, #a7a7a7, #d0d0d0);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#d0d0d0), to(#a7a7a7));
    border: 1px solid #515151;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 1px 8px 15px #111111;
    font-size: 12px;
    height: 27px;
    padding: 5px 8px;
    text-align: center;
    text-shadow: 0 1px 0 #ebebeb;
		position: relative;
		z-index: 3;
	}
}


#menu {
	background: #dee4ea;
	border-right: 1px solid #b4b4b4;
	width: 186px;

	& > ul {

		& > li {
			padding-top: 8px;

			h4 {
				color: #738293;
				font-size: 11px;
				font-weight: bold;
				height: 9px;
				line-height: 8px;
				margin: 0 0 3px;
				padding: 0 7px 0 20px;
				text-transform: uppercase;
				text-shadow: 0 1px 0 #f0f3f7;
			}

			ul li  {
				&.active {
					border-top: 1px solid #6ca0da;
					border-bottom: 1px solid #4376c5;
					background: rgb(79,131,209);
background: -moz-linear-gradient(0deg, rgba(79,131,209,1) 0%, rgba(127,179,237,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(79,131,209,1) 0%, rgba(127,179,237,1) 100%);
background: linear-gradient(0deg, rgba(79,131,209,1) 0%, rgba(127,179,237,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4f83d1",endColorstr="#7fb3ed",GradientType=1);

					a {
						color: #fff;
						text-shadow: 0 1px 0 #1d4e7f;
						font-weight: bold;
					}
				}


				a {
					background-color: transparent;
					background-repeat: no-repeat;
					background-position: 20px center;
					background-size: 16px auto;
					color: #000;
					font-size: 11px;
					line-height: 18px;
					padding: 5px 3px 5px 43px;
					display: block;
					line-height: 1;
				}

				&#favorites {
					&_music {
						a { 
							background-image: url(./images/nav/music.png);
						}
					}

					&_visual {
						a { 
							background-image: url(./images/icons/iphoto.png);
						}
					}

					&_contact {
						a { 
							background-image: url(./images/icons/Mail-32x32.png);
						}
					}
				}

				&#device {
					&_website {
						a { 
							background-image: url(./images/nav/hard-drive.png);
						}
					}

					&_spotify {
						a { 
							background-image: url(./images/icons/Spotify.svg);
						}
					}

					&_itunes {
						a { 
							background-image: url(./images/icons/iTunes-32x32.png);
						}
					}

					&_twitter {
						a { 
							background-image: url(./images/nav/twitter.png);
						}
					}

					&_soundcloud {
						a { 
							background-image: url(./images/nav/soundcloud.png);
						}
					}

					&_facebook {
						a { 
							background-image: url(./images/icons/facebook.png);
						}
					}

					&_globalwav {
						a { 
							background-image: url(./images/icons/global-wav.png);
						}
					}	
				}
				
			}
		}
	}

	&.active {
		background: rgb(29,108,186);
		background: -moz-linear-gradient(0deg, rgba(29,108,186,1) 0%, rgba(101,156,208,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(29,108,186,1) 0%, rgba(101,156,208,1) 100%);
		background: linear-gradient(0deg, rgba(29,108,186,1) 0%, rgba(101,156,208,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d6cba",endColorstr="#659cd0",GradientType=1);
		border-top: 1px solid #5e91c2;
		border-bottom: 1px solid #265fa7;
		font-weight: bold;

		a {
			color: #fff;
			text-shadow: 0 1px 0 #1d4e7f;
		}
	}


}

.Collapsible {
	&__trigger {
		background: url(./images/icons/sidebar-arrow.png) no-repeat;

		&.is-open {	
			background-position: 7px -9px;
		}

		&.is-closed {
			background-position: 7px top;
		}
	}
}

.btn {
	&__info {
		border: 1px solid #818181;
		background: rgb(214,214,214);
		background: -moz-linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(242,242,242,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(242,242,242,1) 100%);
		background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(242,242,242,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d6d6d6",endColorstr="#f2f2f2",GradientType=1);
		color: #000;
		text-shadow: 0 1px 0 #ebebeb;
		font-size: 11px;
		text-align: center;
		border-radius: 9px;
		height: 20px;
		padding: 4px 15px;
		line-height: .9;
		display: inline-block;
	}
}



.transition-opacity {
  transition: opacity 2s ease-in-out;
}

.duration-200 {
  transition-duration: 2s; /* Adjust this value to control the fade duration */
}